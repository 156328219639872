<template>
  <div class="activeDiscount">
    <div class="activeDiscountHeader"></div>
    <div class="inputBox">
      <input type="text" v-model="userInputCord" />
    </div>
    <div class="subBtn" @click="activeCount()">激活</div>
  </div>
</template>
<style scoped lang='scss'>
.activeDiscount {
  .activeDiscountHeader {
    width: 690px;
    min-height: 180px;
    margin: 0 auto;
    margin-top: 30px;
    background: url("~@/assets/userCenter/lhl.png") no-repeat;
    background-size: 100% auto;
  }
  .inputBox {
    width: 690px;
    height: 78px;
    overflow: hidden;
    margin: 30px auto;
    border-radius: 15px;
    background: #dadada;
    input {
      border: none;
      outline: none;
      width: 100%;
      height: 78px;
      text-align: center;
      line-height: 60px;
      display: block;
      font-size: 24px;
      background: #dadada;
    }
  }
  .subBtn {
    width: 690px;
    height: 80px;
    margin: 0 auto;
    border-radius: 15px;
    text-align: center;
    line-height: 80px;
    font-size: 30px;
    color: #ffffff;
    background: #188de7;
  }
}
</style>
<script>
export default {
  data() {
    return {
      userInputCord: "",
    };
  },
  methods: {
    activeCount() {
      // this.request.post('/api/scm/store/coupon/user/active',{activeCode:this.userInputCord}).then(res =>{
      this.request
        .post(
          "/api/scm/store/coupon/activate?exchangeCode=" + this.userInputCord,
          { exchangeCode: this.userInputCord }
        )
        .then((res) => {
          if (res) {
              console.log(res)
              var message = "";
              console.log('a')
              switch (res) {
                case 0:
                  message = "优惠券不存在";
                  break;
                case 1:
                  message = "优惠券已下架";
                  break;
                case 2:
                  message = "不在领取时间";
                  break;
                case 3:
                  message = "无权领取";
                  break;
                case 4:
                  message = "数量已发完";
                  break;
                case 5:
                  message = "已领过该优惠券";
                  break;
                case 6:
                  message = "领取失败";
                  break;
                case 7:
                  message = "激活码错误";
                  break;
                case 8:
                  message = "无可领取的优惠券";
                  break;
                case 9:
                  message = "领取成功";
                  break;
                default:
                    console.log(data)
              }
             this.$toast({
                    message: message,
                    position: "bottom"
                });
            }       
        });
    },
  },
};
</script>