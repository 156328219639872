<template>
    <div class="discountPage">
        <div class="discountTab">
            <div v-for="(item,index) in tabList" :key="index" :class="index==0?'sel':''" @click="tabChange(item.component,index)" ref="tabItem">{{item.name}}</div>
        </div>
        <div class="zw"></div>
        <component :is="needModuleCompoenent"></component>
    </div>
</template>
<script>
import getDiscount from './discountPage/getDiscount.vue'
import myDiscount from './discountPage/myDiscount.vue'
import activeDiscount from './discountPage/activeDiscount.vue'
export default {
    name: "discountPage",
    data() {
        return {
            tabList:[{name:'领取优惠券',component:'getDiscount'},{name:'我的优惠券',component:'myDiscount'},{name:'激活优惠券',component:'activeDiscount'}],
            needModuleCompoenent:'getDiscount'
         }
    },
    components: {
        getDiscount,
        myDiscount,
        activeDiscount
  
    },
    mounted(){
        this.getAuto();
    },
    methods:{
        getAuto(){
            this.request.post('/api/scm/store/coupon/autoSend',{})
        },
       tabChange(componentName,index){
           if(this.needModuleCompoenent==componentName){return}
        //    this.$refs.tabItem.foreach((item,itemIndex) =>{
        //        item.className =''
        //    })
           for(let i =0 ;i<this.$refs.tabItem.length;i++){
               this.$refs.tabItem[i].className =''
           }

           this.$refs.tabItem[index].className = 'sel'
           this.needModuleCompoenent = componentName;

       }
    }
}
</script>
<style scoped lang='scss'>
    .discountPage{
        width: 750px;
        min-height: 100vh;
        background: #f5f5f5;
        overflow: hidden;
        .zw{
            width: 750px;
            height: 100px;
        }
        .discountTab{
            width: 750px;
            height: 100px;
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 3;
            div{
            width: 230px;
            height: 100px;
            text-align: center;
            line-height: 100px;
            font-size: 28px;
            color: #000;
        }
        .sel{
            color: #169BD5;
        }
        }
        
    }
</style>