<template>
    <div class="myDiscount">
       <!-- <div class="discountItem" v-for="(discount,index) in discountList" :key="index" ref="discountCode" @click="openDesc(index)">
              <div class="discountItemTop">
              <div class="itemLeft">
                <p class="itemNum">{{discount.couponPrice}}</p>
                <p class="itemLimit">{{'满'+discount.useMinPrice+'可使用'}}</p>
              </div>
              <div class="discountCenter">
                <div class="discountType">
                  <div class="name"><span class="type">{{discount.ctype=='0'?'通用券':'商品券'}}</span>{{discount.couponTitle}}</div>
                </div>
                <ul class="limit">
                  <li>不可叠加使用</li>
                  <li>{{(discount.endTime).split(' ')[0]+'到期'}}</li>
                </ul>
              </div>
              <div class="btnWrap"> <div class="btn">已领取</div></div>
              </div>
            <div class="discountItemBottom" style="display:none" ref="discountBottom">
                <p class="limitCategory">{{'限品类:'+discount.limitCategory}}</p>
                <p class="limitPlantform">{{'限平台:'+discount.limitPlantform}}</p>
                <p class="code">{{'券编号:'+discount.code}}</p>
            </div>
        </div> -->
         <div class="discountItem" v-for="(discount,index) in discountList" :key="index" ref="discountCode" v-show="!discount.available">
          <div class="itemTop">
            <div class="itemTopLeft">
                <p class="itemNum">{{discount.couponPrice}}</p>
                <p class="itemLimit">{{'满'+discount.useMinPrice+'可使用'}}</p>
            </div>
            <div class="itemTopCenter">
                <div class="name"> <span class="type">{{discount.couponType=='0'?'通用券':'商品券'}}</span>{{discount.couponTitle}}</div>
                <div class="limitData">有效期至:{{(discount.couponEndTime).split(' ')[0]}}</div>
            </div>
            <div class="itemTopRight" @click="jumpShop()">去使用</div>
          </div>
          <div class="itemBottom">
            <div class="seeDetail" @click="openDesc(index)">
              <p>不可叠加使用</p>
              <p ref='arrowDown'>查看详情<span class="arrowBottom"></span></p>
            </div>
            <div class="visiDetail" ref="detail" @click="closeDesc(index)">
              <div class="discountItemBottom"  ref="discountBottom" v-html="discount.statement">
                <!-- <p class="limitCategory">{{'限品类:'+discount.limitCategory}}</p>
                <p class="limitPlantform">{{'限平台:'+discount.limitPlantform}}</p>
                <p class="code">{{'券编号:'+discount.code}}</p> -->
                   <!-- {{discount.statement}} -->
              </div>
              <p class="detailUp">查看详情<span class="arrowUp"></span></p>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "myDiscount",
    data() {
        return {
            discountList:[]
         }
    },
    components: {
       
  
    },
    mounted(){
        this.getDiscountData();
    },
    methods:{
      jumpShop(){
        this.$router.push({
        path: "/h/scm/mall/hotSale",
        query: {
          scence: this.$route.query.sceneNo
        },
      });
      },
        openDesc(index){
          var detailItem = this.$refs.detail;
          var arrowDown = this.$refs.arrowDown;
          arrowDown[index].className = 'visiBity'
          detailItem[index].className = 'detail'
        },
        closeDesc(index){
          var detailItem = this.$refs.detail;
          var arrowDown = this.$refs.arrowDown;
          arrowDown[index].className = ''
          detailItem[index].className = 'visiDetail'
        },
        returnLimit(useMinPrice,couponPrice){
            return '满'+useMinPrice+'减'+couponPrice
        },
        getDiscountData(){
            // this.request.post('/api/scm/store/coupon/user/list',{}).then(res =>{
            this.request.post('/api/scm/store/coupon/list',{
                status:0
            }).then(res =>{
                console.log(res)
                this.discountList = res.list;
            })
        },
        getCard(cliId){
            this.request.post('/api/scm/store/coupon/user/revice',{ciid:cliId}).then(res =>{
                console.log(res)
                if(res){
                    this.$toast({
                        message: "领取优惠券成功",
                        position: "bottom"
                    });
                    this.getDiscountData();
                } 
            })
        }
    }
}
</script>
<style scoped lang='scss'>
    .visiBity{
      visibility: hidden;
    }
    .arrowBottom{
      display: inline-block;
      width: 18px;
      height: 11px;
      background: url('~@/assets/userCenter/arrowDown.png') no-repeat;
      background-size: 100% 100%;
    }
    .arrowUp{
      display: inline-block;
      width: 18px;
      height: 11px;
      background: url('~@/assets/userCenter/arrowUp.png') no-repeat;
      background-size: 100% 100%;
    }
    .myDiscount{
        width: 750px;
       .discountItem,.discountSelItem{
          width: 710px;
        //   height: 160px;
          margin:20px auto;
          background: #f7deaf;
          box-sizing: border-box;
          border-radius: 0.1rem;
          .itemTop{
            height: 130px;
            width: 635px;
            margin:0 auto;
            display: flex;
            display: -webkit-flex;
            border-bottom:1px dashed #dcb268;
            align-items: center;
            .itemTopLeft{
              width: 200px;
              height: 100%;
              display: flex;
              display: -webkit-flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .itemNum{
                font-size: 48px;
                color: #8b5d0e;
                font-weight: bold;
                position: relative;
              }
              .itemNum::before{
                content: '￥';
                position: absolute;
                top: 5px;
                left: -20px;
                z-index: 1;
                font-size: 22px;

              }
              .itemLimit{
                font-size: 20px;
                color: #c3a167;
              }
            }
            .itemTopCenter{
              width: 310px;
              display: flex;
              display: -webkit-flex;
              flex-direction: column;
              justify-content: center;
              .name{
                font-size: 20px;
                color: #777065;
                .type{
                  font-size: 30px;
                  color: #000000;
                  font-weight: bold;
                }
              
              }
              .limitData{
                padding-top: 15px;
                font-size: 22px;
                color: #e7ca98;
              }
            }
            .itemTopRight{
              font-size: 24px;
              width: 130px;
              height: 48px;
              color: #c69865;
              background: #eaca96;
              border-radius: 20px;
              text-align: center;
              line-height: 48px;
            }
          }
          .itemBottom{
            min-height: 50px;
            width: 635px;
            margin: 0 auto;
            .seeDetail{
              height: 50px;
              display: flex;
              display: -webkit-flex;
              justify-content: space-between;
              align-items: center;
              p{
                font-size: 20px;
                color: #8b5d0e;
              }

            }
            .visiDetail{
               position: relative;
               display:none;
              .discountItemBottom{
                width: 635px;
                margin: 0 auto;
                padding:20px 0;
                font-size: 20px;
                color: #b08b4c;
            }
            .detailUp{  
                position: absolute;
                right: 0;
                bottom: 20px;
                font-size: 20px;
                color: #8b5d0e;
            }
            }
            .detail{
               position: relative;
               display: block;
              .discountItemBottom{
                width: 635px;
                margin: 0 auto;
                padding:20px 0;
                font-size: 20px;
                color: #b08b4c;
            }
            .detailUp{  
                position: absolute;
                right: 0;
                bottom: 20px;
                font-size: 20px;
                color: #8b5d0e;
            }
            }
          
          }
        }
    }
</style>