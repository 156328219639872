<template>
  <div class="getDiscount">
    <div
      class="discountItem"
      v-for="(discount, index) in discountList"
      :key="index"
      ref="discountCode"
      v-show="!discount.available"
    >
      <div class="itemTop">
        <div class="itemTopLeft">
          <p class="itemNum">{{ discount.couponPrice }}</p>
          <p class="itemLimit">{{ "满" + discount.useMinPrice + "可使用" }}</p>
        </div>
        <div class="itemTopCenter">
          <div class="name">
            <span class="type">{{
              discount.type == "0" ? "通用券" : "商品券"
            }}</span
            >&ensp;{{ discount.title }}
          </div>
          <div class="limitData">
            有效期至:{{ discount.useEffectiveEnd.split(" ")[0] }}
          </div>
        </div>
        <div class="itemTopRight" @click="getCard(discount.id)">领取</div>
      </div>
      <div class="itemBottom">
        <div class="seeDetail" @click="openDesc(index)">
          <p>不可叠加使用</p>
          <p ref="arrowDown">查看详情<span class="arrowBottom"></span></p>
        </div>
        <div class="visiDetail" ref="detail" @click="closeDesc(index)">
          <div class="discountItemBottom" ref="discountBottom" v-html="discount.statement">
            <!-- <p class="limitCategory">
              {{ "限品类:" + discount.limitCategory }}
            </p>
            <p class="limitPlantform">
              {{ "限平台:" + discount.limitPlantform }}
            </p> -->
            <!-- {{discount.statement}} -->
          </div>
          <p class="detailUp">查看详情<span class="arrowUp"></span></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "getDiscount",
  data() {
    return {
      discountList: [],
      getFinish: true,
    };
  },
  components: {},
  mounted() {
    this.getDiscountData();
  },
  methods: {
    openDesc(index) {
      var detailItem = this.$refs.detail;
      var arrowDown = this.$refs.arrowDown;
      arrowDown[index].className = "visiBity";
      detailItem[index].className = "detail";
    },
    closeDesc(index) {
      var detailItem = this.$refs.detail;
      var arrowDown = this.$refs.arrowDown;
      arrowDown[index].className = "";
      detailItem[index].className = "visiDetail";
    },
    returnLimit(useMinPrice, couponPrice) {
      return "满" + useMinPrice + "减" + couponPrice;
    },
    // getDiscountData(){
    //     this.request.post('/api/scm/store/coupon/issue/receive/list',{}).then(res =>{
    //         console.log(res)
    //         this.discountList = res.list;
    //     })
    // },
    getDiscountData() {
      this.request.post("/api/scm/store/coupon/collectList", {
    
      }).then((res) => {
        console.log(res);
        this.discountList = res.list;
      });
    },
    getCard(cliId) {
      if (this.getFinish) {
        this.getFinish = false;
        this.request
          .post("/api/scm/store/coupon/collect?couponId=" + cliId, {
            couponId: cliId,
          })
          .then((res) => {
            this.getFinish = true;
            console.log(res);
            if (res) {
              // this.$toast({
              //     message: "领取优惠券成功",
              //     position: "bottom"
              // });
              var message = "";
              console.log("a");
              switch (res) {
                case 0:
                  message = "优惠券不存在";
                  break;
                case 1:
                  message = "优惠券已下架";
                  break;
                case 2:
                  message = "不在领取时间";
                  break;
                case 3:
                  message = "无权领取";
                  break;
                case 4:
                  message = "数量已发完";
                  break;
                case 5:
                  message = "已领过该优惠券";
                  break;
                case 6:
                  message = "领取失败";
                  break;
                case 7:
                  message = "激活码错误";
                  break;
                case 8:
                  message = "无可领取的优惠券";
                  break;
                case 9:
                  message = "领取成功";
                  break;
                default:
                  console.log(data);
              }
              this.$toast({
                message: message,
                position: "bottom",
              });

              this.getDiscountData();
            }
          });
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.visiBity {
  visibility: hidden;
}
.arrowBottom {
  display: inline-block;
  width: 18px;
  height: 11px;
  background: url("~@/assets/userCenter/arrowDown.png") no-repeat;
  background-size: 100% 100%;
}
.arrowUp {
  display: inline-block;
  width: 18px;
  height: 11px;
  background: url("~@/assets/userCenter/arrowUp.png") no-repeat;
  background-size: 100% 100%;
}
.getDiscount {
  width: 750px;
  .discountItem,
  .discountSelItem {
    width: 710px;
    //   height: 160px;
    margin: 20px auto;
    background: #f7deaf;
    box-sizing: border-box;
    border-radius: 0.1rem;
    .itemTop {
      height: 130px;
      width: 635px;
      margin: 0 auto;
      display: flex;
      display: -webkit-flex;
      border-bottom: 1px dashed #dcb268;
      align-items: center;
      .itemTopLeft {
        width: 200px;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .itemNum {
          font-size: 48px;
          color: #8b5d0e;
          font-weight: bold;
          position: relative;
        }
        .itemNum::before {
          content: "￥";
          position: absolute;
          top: 5px;
          left: -20px;
          z-index: 1;
          font-size: 22px;
        }
        .itemLimit {
          font-size: 20px;
          color: #c3a167;
        }
      }
      .itemTopCenter {
        width: 310px;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: center;
        .name {
          font-size: 20px;
          color: #777065;
          .type {
            font-size: 30px;
            color: #000000;
            font-weight: bold;
          }
        }
        .limitData {
          padding-top: 15px;
          font-size: 22px;
          color: #e7ca98;
        }
      }
      .itemTopRight {
        font-size: 24px;
        width: 130px;
        height: 48px;
        color: #ffeac8;
        background: #c49562;
        border-radius: 20px;
        text-align: center;
        line-height: 48px;
      }
    }
    .itemBottom {
      min-height: 50px;
      width: 635px;
      margin: 0 auto;
      .seeDetail {
        height: 50px;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-size: 20px;
          color: #8b5d0e;
        }
      }
      .visiDetail {
        position: relative;
        display: none;
        .discountItemBottom {
          width: 635px;
          margin: 0 auto;
          padding: 20px 0;
          font-size: 20px;
          color: #b08b4c;
        }
        .detailUp {
          position: absolute;
          right: 0;
          bottom: 20px;
          font-size: 20px;
          color: #8b5d0e;
        }
      }
      .detail {
        position: relative;
        display: block;
        .discountItemBottom {
          width: 635px;
          margin: 0 auto;
          padding: 20px 0;
          font-size: 20px;
          color: #b08b4c;
        }
        .detailUp {
          position: absolute;
          right: 0;
          bottom: 20px;
          font-size: 20px;
          color: #8b5d0e;
        }
      }
    }
  }
}
</style>